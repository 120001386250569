<template>
  <div class="home">Welcome to Customer Care</div>
</template>

<script>
export default {
  name: 'ViewHome',
  setup() {},
};
</script>
